const productionAPIServerLink = 'https://metatestbackend.djvirtualevents.com'
export const LOGOUT_LINK = "https://idm.dev.zsservices.com/DJ/IdentityManager/app/Web/Logout.aspx"
const localAPIServerLink = '/api'

export const AuthUserCookieName = ".Oauth2.DJ.Meta"
export let SERVER_DOMAIN = "localhost"
let serverURL = productionAPIServerLink
if (window.location.href.includes('localhost')) {
    serverURL = localAPIServerLink
    SERVER_DOMAIN = "localhost"
}

export const Endpoints = {
    "root": serverURL + "/",
    "logout": serverURL + "/logoutUser",
    "verifyadmin": serverURL + "/verifyadmin",
    "verifyuser": serverURL + '/verifyuser',
    "vcroom": serverURL + '/vcroom',
    "dailycoToken": serverURL + '/generate-videocall-Token',
    "quizresponse": serverURL + '/quizresponse',
    "signup": serverURL + '/signup',
    "RoomPoints": {
        "addRoomAdmin": serverURL + "/addRoomAdmin",
        "updateRoomAdmin": serverURL + "/updateroomadmin",
        "getRoomAdmin": serverURL + "/getRoomAdmin",
        "setNotification": serverURL + "/setNotification",
        "getNotification": serverURL + "/getNotification",
        "vcAdminCheck": serverURL + "/vcAdminCheck"
    },
    "Photobooth": "/photobooth/index.html",
    "InfiniteRunner": "/InfiniteRunner/index.html",
    "Exihibition": "/TrainingRoom/index.html",
}

export const EndpintsReturnCodes = {
    "OK": "OK"
}