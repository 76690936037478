import { useState } from 'react';
import { loadUser } from '../../Firebase/auth';
import style from './index.module.css';
import img_metaLogo from './metaLogo.png'

function ValidateEmail(mail) {

    alert("You have entered an invalid email address!")
    return (false)
}

const validateForm = (name, email) => {
    if (!name || !email) {
        return false
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
    } else {
        return false
    }
}

export default function Login() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [error, toggleError] = useState(false)
    const [userNotR, toggleUserNotR] = useState(false)
    const [loading, toggleloading] = useState(false)

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            toggleloading(true);
            toggleUserNotR(false);
            toggleError(false);
            let _email = email.toLowerCase().trim();
            const isValidForm = validateForm(name, _email);
            if (isValidForm) {
                toggleError(false);
                await loadUser(_email, name, true);
            } else {
                toggleError(true);
            }
            toggleloading(false);
        } catch (error) {
            let { code } = error
            if (code == "auth/user-not-found") {
                toggleloading(false);
                toggleUserNotR(true);
            } else {
                toggleloading(false);
                toggleError(true);
            }
        }
    };

    return (
        <div className={style.container}>
            <div className={`${style.dflex} ${style.formContainer}`}>
                <img src={img_metaLogo} alt="meta-logo" />
                <form className={`${style.dflex}`} onSubmit={handleSubmit}>
                    <input className={style.login_input} type='text' placeholder='Your Name' onChange={(e) => setName(e.target.value)} value={name} required />
                    <input className={style.login_input} type='text' placeholder='Your Email Id' onChange={(e) => setEmail(e.target.value)} value={email} required />
                    {
                        error &&
                        <p>
                            *Enter Valid Input
                        </p>
                    }
                    {
                        userNotR &&
                        <p>
                            *User Not Registered
                        </p>
                    }
                    <button type='submit' className={style.dflex}>
                        {
                            loading ?
                                <img src="./assets/images/Loader.gif" alt="loading" />
                                :
                                "Enter"

                        }
                    </button>
                </form>
            </div>
        </div>
    )
}
